/* eslint-disable import/no-cycle */
import { PersistentStateLoadingState } from '../types';
import { rxAlert as _rxAlert } from './alert.platform';
import { documentSearchEngine } from './documentSearchEngine';
import _handleStateUpdateSideEffects from './handleStateUpdateSideEffects';
import _onDatabaseSchemaConflict from './onDatabaseSchemaConflict';
import * as otherClientStateUpdaters from './stateUpdaters/clientStateUpdaters/other';
import * as infrastructureRelatedTransientUpdaters from './stateUpdaters/persistentStateUpdaters/infrastructureRelated';
import {
  incrementPersistentStateLoadedDocumentCountByN,
  setAreServerUpdatesBeingAppliedToForeground,
  setAreServerUpdatesBeingFetchedByUser,
  setCurrentPersistentStateLoadingState,
  setPersistentStateTotalDocumentsToAddCount,
} from './stateUpdaters/persistentStateUpdaters/infrastructureRelated';
import { setAreAllDatabaseHooksDisabled } from './stateUpdaters/transientStateUpdaters/database';
import * as feedTransientUpdaters from './stateUpdaters/transientStateUpdaters/feed';
import * as linkTransientStateUpdaters from './stateUpdaters/transientStateUpdaters/links';

export const setProfile: typeof otherClientStateUpdaters.setProfile = (...args) =>
  otherClientStateUpdaters.setProfile(...args);

export const searchInit: typeof documentSearchEngine.init = (...args) =>
  documentSearchEngine.init(...args);
export const searchUpsertDocuments: typeof documentSearchEngine.upsertDocuments = (...args) =>
  documentSearchEngine.upsertDocuments(...args);
export const searchDestroy: typeof documentSearchEngine.destroy = (...args) =>
  documentSearchEngine.destroy(...args);

export { findDocsForParsedDocIds } from './database/getters';

export const onBackgroundStateUpdates: typeof infrastructureRelatedTransientUpdaters.onBackgroundStateUpdates =
  (...args) => infrastructureRelatedTransientUpdaters.onBackgroundStateUpdates(...args);

export const disableAllDatabaseHooks = async () => {
  await setAreAllDatabaseHooksDisabled(true);
};

export const fetchRelatedRSS: typeof feedTransientUpdaters.fetchRelatedRSS = (...args) =>
  feedTransientUpdaters.fetchRelatedRSS(...args);

export const onRSSFeedsLoaded: typeof feedTransientUpdaters.onRSSFeedsLoaded = (...args) =>
  feedTransientUpdaters.onRSSFeedsLoaded(...args);

export const handleStateUpdateSideEffects: typeof _handleStateUpdateSideEffects = (...args) =>
  _handleStateUpdateSideEffects(...args);

export const getCurrentPersistentStateWithDocuments: typeof infrastructureRelatedTransientUpdaters.getCurrentPersistentStateWithDocuments =
  (...args) => infrastructureRelatedTransientUpdaters.getCurrentPersistentStateWithDocuments(...args);
export const runForegroundStateChecksum: typeof infrastructureRelatedTransientUpdaters.runForegroundStateChecksum =
  (...args) => infrastructureRelatedTransientUpdaters.runForegroundStateChecksum(...args);

export const updateDocumentLinks: typeof linkTransientStateUpdaters.updateDocumentLinks = (...args) =>
  linkTransientStateUpdaters.updateDocumentLinks(...args);

export const updatePersistentStateLoadingState = async (loadingState: PersistentStateLoadingState) => {
  setCurrentPersistentStateLoadingState(loadingState);
};
export const updatePersistentStateTotalNumberOfDocumentsToLoad = async (documentsCount: string) => {
  setPersistentStateTotalDocumentsToAddCount(documentsCount);
};

export const updatePersistentStateLoadedDocumentCountByN = async (n: number) => {
  incrementPersistentStateLoadedDocumentCountByN(n);
};

export const setAreServerChangesBeingAppliedToForeground = async (changesAreBeingUpdated: boolean) => {
  setAreServerUpdatesBeingAppliedToForeground(changesAreBeingUpdated);
};

export const onConsumeServerToForegroundUpdatesFinished = async () => {
  setAreServerUpdatesBeingFetchedByUser(false);
};

export const rxAlert: typeof _rxAlert = _rxAlert;

export const onDatabaseSchemaConflict: typeof _onDatabaseSchemaConflict = _onDatabaseSchemaConflict;
