// eslint-disable-next-line import/no-cycle
import { selectImageElementToHighlight } from '../mobile-library/contentFrameInternals/getImagePressingInitializer';
import { getScrollingManager } from '../mobile-library/contentFrameInternals/getScrollingManager';
import { computeHeaderImageColorData } from '../mobile-library/contentFrameInternals/imageUtils';
import { renderTags } from '../mobile-library/contentFrameInternals/initTags';
import { updateTTSButton } from '../mobile-library/contentFrameInternals/initTTSButton';
import { setYTAutoScrolling } from '../mobile-library/contentFrameInternals/initYouTubeTeleprompter';
import { PaginatedScrollingManager } from '../mobile-library/contentFrameInternals/PaginatedScrollingManager';
import {
  clearSearch,
  findAgain,
  findNext,
  findPrev,
  search,
} from '../mobile-library/contentFrameInternals/searchUtils';
import { MobileContentFrameWindow } from '../mobile-library/contentFrameInternals/types';
import { VerticalScrollingManager } from '../mobile-library/contentFrameInternals/VerticalScrollingManager';
import { ThemeCSSMap } from '../mobile-library/css/baseStyles';
import {
  getWebviewInnerStyle,
  WebviewStyleProps,
} from '../mobile-library/ReaderWebview/WebviewHTML/styles';
import getWebviewHorizontalMarginCss from '../mobile-library/utils/getWebviewHorizontalMarginCss';
import { Category, LenientReadingPosition, TtsPosition } from '../types';
import type { DocumentTag } from '../types/tags';
import { isInReactNativeWebView } from '../utils/environment';

declare let window: MobileContentFrameWindow;
const ensureIsMobileWebview = () => {
  if (!isInReactNativeWebView) {
    throw new Error('Cannot call this function outside react native webview');
  }
};

export const mobileSetStyles = ({
  deviceHeight,
  isDigestView,
  isEmailOriginalView,
  isPaginatedMode,
  isPDFDigestPreview,
  isYoutubeVideo,
  font,
  areEpubOriginalStylesEnabled,
  marginTop,
  isHighContrast,
  horizontalMargin,
  fontSize,
  lineHeight,
  shouldJustifyText,
  isRtl,
  theme,
  isTablet,
  platformOS,
}: WebviewStyleProps) => {
  ensureIsMobileWebview();
  window.theme = theme;
  const styleElement = document.getElementById('generatedStyles');
  if (!styleElement) {
    return;
  }
  styleElement.innerHTML = getWebviewInnerStyle({
    deviceHeight,
    isDigestView,
    isEmailOriginalView,
    isPaginatedMode,
    isPDFDigestPreview,
    isYoutubeVideo,
    font,
    areEpubOriginalStylesEnabled,
    marginTop,
    isHighContrast,
    horizontalMargin,
    fontSize,
    lineHeight,
    shouldJustifyText,
    theme,
    isRtl,
    isTablet,
    platformOS,
  });
  document.body.classList.remove(...Object.values(ThemeCSSMap));
  document.body.classList.add(ThemeCSSMap[theme.type]);
  document.body.classList.remove('high-contrast');
  if (isHighContrast) {
    document.body.classList.add('high-contrast');
  }
  computeHeaderImageColorData();
};
export const mobileCreateHighlightAtTtsPosition = async (ttsPos: TtsPosition) => {
  ensureIsMobileWebview();
  getScrollingManager().createHighlightAtTtsPosition(ttsPos);
};

export const mobileSetReadingPosition = (pos: LenientReadingPosition | null) => {
  ensureIsMobileWebview();
  getScrollingManager().setReadingPosition(pos);
};
export const mobileReturnToReadingPosition = () => {
  ensureIsMobileWebview();
  getScrollingManager().returnToReadingPosition();
};

export const mobileScrollToTop = () => {
  ensureIsMobileWebview();
  getScrollingManager().scrollToTop();
};

export const mobileToggleScrollingEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleScrollingEnabled(enabled);
};

export const mobileToggleTTSAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  getScrollingManager().toggleTTSAutoScrolling(enabled);
};

export const mobilePlayTtsFromCurrentScrollPosition = () => {
  ensureIsMobileWebview();
  getScrollingManager().playTtsFromCurrentScrollPosition();
};

export const updateEndOfReadingText = (text: string) => {
  ensureIsMobileWebview();
  const endOfReadingText = document.getElementById('end-of-reading-text');
  if (!endOfReadingText) {
    return;
  }
  endOfReadingText.innerHTML = text;
};

export const mobileSelectImageElementToHighlight = async (imageId: string) => {
  ensureIsMobileWebview();
  const image = document.getElementById(imageId) as HTMLImageElement;
  if (image === null) {
    throw new Error('highlightImage - couldnt find image with provided id');
  }
  return selectImageElementToHighlight(image);
};

export const mobileChangeAutohighlightingStatus = async (isEnabled: boolean) => {
  ensureIsMobileWebview();
  window.isAutoHighlightingEnabled = isEnabled;
  const textContent = document.getElementById('document-text-content');
  if (!textContent) {
    return;
  }
  if (isEnabled) {
    textContent.classList.add('document-text-content--auto-highlighting-enabled');
  } else {
    textContent.classList.remove('document-text-content--auto-highlighting-enabled');
  }
};

export const mobileRenderTags = (tags: DocumentTag[]) => {
  ensureIsMobileWebview();
  renderTags(tags);
};

export const mobileUpdateTTSButton = (isListeningToThisDoc: boolean) => {
  ensureIsMobileWebview();
  updateTTSButton(isListeningToThisDoc, true);
};

export const mobileToggleYTAutoScrolling = (enabled: boolean) => {
  ensureIsMobileWebview();
  setYTAutoScrolling(enabled);
};

export const mobileSearchText = (text: string) => {
  ensureIsMobileWebview();
  search(text);
};
export const mobileSearchFindAgain = (index: number) => {
  ensureIsMobileWebview();
  findAgain(index);
};
export const mobileSearchFindNext = (index: number) => {
  ensureIsMobileWebview();
  findNext(index);
};

export const mobileSearchFindPrev = (index: number) => {
  ensureIsMobileWebview();
  findPrev(index);
};
export const mobileClearSearch = () => {
  ensureIsMobileWebview();
  clearSearch();
};

export const mobileMaybeScrollToTopOnMenuOpen = () => {
  ensureIsMobileWebview();
  if (!window?.scrollingManager || !window.scrollingManager.firstTimeOpenedDocumentOffset) {
    return;
  }
  if (
    window.scrollingManager.getScrollingElementTop() <=
    window.scrollingManager.firstTimeOpenedDocumentOffset + 12
  ) {
    window.scrollingManager.scrollToTop();
  }
};
export const mobileTogglePaginationAnimationsEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled) {
    return;
  }
  window.pagination.smoothAnimationsDisabled = enabled;
};
export const mobileTogglePaginationHapticsOnScrollEnabled = (enabled: boolean) => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled) {
    return;
  }
  window.pagination.hapticsOnScrollEnabled = enabled;
};

export const mobileScrollToNextPage = () => {
  ensureIsMobileWebview();
  if (
    !window.pagination ||
    !window.pagination.enabled ||
    !(window.scrollingManager instanceof PaginatedScrollingManager)
  ) {
    return;
  }
  const scrollingManager = window.scrollingManager as PaginatedScrollingManager;
  scrollingManager.scrollToPage(scrollingManager.currentPage + 1);
};

export const mobileScrollToPreviousPage = () => {
  ensureIsMobileWebview();
  if (
    !window.pagination ||
    !window.pagination.enabled ||
    !(window.scrollingManager instanceof PaginatedScrollingManager)
  ) {
    return;
  }
  const scrollingManager = window.scrollingManager as PaginatedScrollingManager;
  scrollingManager.scrollToPage(scrollingManager.currentPage - 1);
};

const BOTTOM_BAR_PLUS_TWO_LINES_OF_TEXT_APPROX_HEIGHT = 110;

export const mobileScrollApproxOnePage = (direction: 'up' | 'down') => {
  ensureIsMobileWebview();
  if (!(window.scrollingManager instanceof VerticalScrollingManager)) {
    return;
  }
  const scrollingManager = window.scrollingManager;
  const scrollOffset = window.innerHeight - BOTTOM_BAR_PLUS_TWO_LINES_OF_TEXT_APPROX_HEIGHT;
  const positionToScroll =
    direction === 'up'
      ? scrollingManager.currentScrollValue - scrollOffset
      : scrollingManager.currentScrollValue + scrollOffset;
  scrollingManager.scrollToPosition(positionToScroll);
};

export const mobileSetContent = async (content: string, category: Category) => {
  ensureIsMobileWebview();
  window.setContent(content, category);
};

export const setHorizontalMargin = async (margin: number, isTablet: boolean) => {
  ensureIsMobileWebview();
  const style = document.getElementById('RWMarginStyle');
  if (!style) {
    return;
  }

  style.innerHTML = getWebviewHorizontalMarginCss(margin, isTablet);
};

export const mobileRetryLoadImages = () => {
  ensureIsMobileWebview();
  if (!window.reloadImagesThatFailedToLoad) {
    return;
  }
  window.reloadImagesThatFailedToLoad();
};

export const refreshPaginationPageSnapshots = async () => {
  ensureIsMobileWebview();
  if (!window.pagination || !window.pagination.enabled) {
    return;
  }
  getScrollingManager().refreshPageSnapshotsForCurrentPage();
};

export const forceScroll = () => {
  ensureIsMobileWebview();
  const scrollingManager = getScrollingManager();
  scrollingManager.enableScrollEvents();
  scrollingManager.onScroll();
};
