import Tippy from '@tippyjs/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';
// eslint-disable-next-line restrict-imports/restrict-imports
import Button from 'reader/web/src/components/Button';
// eslint-disable-next-line restrict-imports/restrict-imports
import AnimatedCheckmarkIcon from 'reader/web/src/components/icons/AnimatedCheckmarkIcon';
// eslint-disable-next-line restrict-imports/restrict-imports
import ErrorIcon from 'reader/web/src/components/icons/ErrorIcon';
// eslint-disable-next-line restrict-imports/restrict-imports
import InfoIcon from 'reader/web/src/components/icons/InfoIcon';
// eslint-disable-next-line restrict-imports/restrict-imports
import SuccessIcon from 'reader/web/src/components/icons/SuccessIcon';
// eslint-disable-next-line restrict-imports/restrict-imports
import WarningIcon from 'reader/web/src/components/icons/WarningIcon';
// eslint-disable-next-line restrict-imports/restrict-imports
import Spinner from 'reader/web/src/components/Spinner';
import { ulid } from 'ulid';

import { isExtension } from '../utils/environment';
import getReaderAppBaseUrl from '../utils/getReaderAppBaseUrl';
import urlJoin from '../utils/urlJoin';
import eventEmitter from './eventEmitter';
import { undoDocumentAction } from './stateUpdaters/transientStateUpdaters/undo';
import Toaster from './Toaster';
import type { TooltipTheme } from './types';

const readerBaseUrl = getReaderAppBaseUrl();

export const createToast: Toaster['createToast'] = ({
  buttonText,
  category = 'default',
  content,
  duration,
  eventToEmitOnUndo,
  hasAnimatedSuccessIcon,
  link,
  linkTooltipContent,
  onButtonClick,
  undoableUserEventId,
}) => {
  const OurToast = ({ closeToast }: ToastContentProps) => {
    const canUndo = Boolean(undoableUserEventId) || Boolean(eventToEmitOnUndo);
    let icon;
    if (category === 'error') {
      icon = <ErrorIcon />;
    } else if (category === 'info') {
      icon = <InfoIcon />;
    } else if (category === 'success') {
      if (hasAnimatedSuccessIcon) {
        icon = <AnimatedCheckmarkIcon text="Success!" />;
      } else {
        icon = <SuccessIcon />;
      }
    } else if (category === 'warning') {
      icon = <WarningIcon />;
    }

    let actionsContainer;
    if (canUndo) {
      actionsContainer = (
        <div className="actionsContainer">
          <Button
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              closeToast?.();
              const userInteraction = 'click';
              if (undoableUserEventId) {
                undoDocumentAction(undoableUserEventId, userInteraction);
              }
              if (eventToEmitOnUndo) {
                eventEmitter.emit(eventToEmitOnUndo);
              }
            }}
          >
            Undo
          </Button>
        </div>
      );
    }

    if (buttonText && onButtonClick) {
      actionsContainer = (
        <div className="actionsContainer">
          <Button
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              closeToast?.();
              onButtonClick();
            }}
          >
            {buttonText}
          </Button>
        </div>
      );
    }

    let contentContainer = (
      <div className="contentContainer">
        {icon}
        <span className="contentWrapper">{content}</span>
      </div>
    );

    if (link) {
      const commonLinkAttributes = {
        className: 'toastLink',
      };

      const isRelativeLinkToReader = !/^https?:\/\//.test(link);
      const linkUrl = isRelativeLinkToReader && isExtension ? urlJoin([readerBaseUrl, link]) : link;

      if (isRelativeLinkToReader && !isExtension) {
        contentContainer = (
          <Link {...commonLinkAttributes} to={linkUrl}>
            {contentContainer}
          </Link>
        );
      } else {
        contentContainer = (
          <a {...commonLinkAttributes} href={linkUrl}>
            {contentContainer}
          </a>
        );
      }

      if (linkTooltipContent) {
        contentContainer = (
          <Tippy
            appendTo={
              (isExtension &&
                (document.querySelector('readwise-tooltip-container')
                  ?.shadowRoot as HTMLElement | null)) ||
              document.body
            }
            content={linkTooltipContent}
            theme={category as TooltipTheme}
          >
            {contentContainer}
          </Tippy>
        );
      }
    }

    return (
      <>
        {contentContainer}
        {actionsContainer}
      </>
    );
  };

  return toast(OurToast, {
    autoClose: duration ?? 4000,
    closeButton: false,
    closeOnClick: false,
    position: isExtension ? toast.POSITION.TOP_RIGHT : toast.POSITION.BOTTOM_LEFT,
    toastId: undoableUserEventId ?? ulid(),
    type: toast.TYPE[category.toUpperCase()],
  }) as string;
};

export function createCancelableToast({
  content,
  onCancelClick,
}: { content: string; onCancelClick: () => void }) {
  const OurToast = () => {
    const contentContainer = (
      <div className="contentContainer">
        <Spinner className="Toastify__loading-spinner" />
        <span className="contentWrapper">{content}</span>
      </div>
    );

    const actionsContainer = (
      <div className="actionsContainer">
        <Button
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            onCancelClick?.();
          }}
        >
          Cancel
        </Button>
      </div>
    );

    return (
      <>
        {contentContainer}
        {actionsContainer}
      </>
    );
  };

  return toast(<OurToast />, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    position: toast.POSITION.BOTTOM_LEFT,
    toastId: ulid(),
    hideProgressBar: true,
  }) as string;
}

export const removeToast: Toaster['removeToast'] = (eventId) => toast.dismiss(eventId);

export const removeAllToasts: Toaster['removeAllToasts'] = () => toast.dismiss();

export default {
  createToast,
  createCancelableToast,
  removeToast,
  removeAllToasts,
} as Toaster;
