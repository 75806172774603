import isEqual from 'lodash/isEqual';

import {
  type HeadphoneAction,
  type MobileDocumentSubpanel,
  type Profile,
  type SwipeDocList,
  type UserEvent,
  ClientState,
  DisplayTheme,
} from '../../../types';
import getServerBaseUrl from '../../../utils/getServerBaseUrl.platform';
// eslint-disable-next-line import/no-cycle
import requestWithAuth from '../../../utils/requestWithAuth.platformIncludingExtension';
// eslint-disable-next-line import/no-cycle
import { CancelStateUpdate, globalState, updateState } from '../../models';
// eslint-disable-next-line import/no-cycle
import { getAutoKindleEmailDelivery } from '../../stateGetters';
// eslint-disable-next-line import/no-cycle
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const toggleAutoAdvance = async (
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  const newValue = !globalState.getState().client.autoAdvance;

  const updateResult = await updateState(
    (state) => {
      state.client.autoAdvance = newValue;
    },
    { ...options, eventName: 'auto-advance-toggled', isUndoable: false },
  );

  if (options.showToast) {
    createToast({
      content: `Auto-advance turned ${newValue ? 'on' : 'off'}`,
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

const setAutoKindleEmailSend = async ({
  isEnabled,
  shouldShowToast,
  userInteraction,
}: {
  isEnabled: boolean;
  shouldShowToast?: boolean;
  userInteraction: string;
}): Promise<void> => {
  await updateState(
    (state) => {
      state.persistent.integrations.kindle ??= {};
      state.persistent.integrations.kindle.isAutomaticKindleEmailEnabled = isEnabled;
    },
    {
      eventName: 'auto-kindle-email-send-setting-updated',
      userInteraction,
    },
  );

  if (shouldShowToast) {
    createToast({
      category: 'success',
      content: `Automatic Delivery ${isEnabled ? 'enabled' : 'disabled'}`,
    });
  }
};

export const toggleAutoKindleEmailSend = (userInteraction: string) => {
  setAutoKindleEmailSend({
    isEnabled: !getAutoKindleEmailDelivery(globalState.getState()),
    shouldShowToast: true,
    userInteraction,
  });
};

export const setKindleExportFrequency = async ({
  exportFrequency,
  userInteraction,
  shouldShowToast = true,
}: {
  exportFrequency: 'weekly' | 'daily';
  userInteraction: string;
  shouldShowToast?: boolean;
}) => {
  await updateState(
    (state) => {
      state.persistent.integrations.kindle ??= {};
      state.persistent.integrations.kindle.exportFrequency = exportFrequency;
    },
    { eventName: 'kindle-export-frequency-updated', userInteraction },
  );

  if (shouldShowToast) {
    createToast({
      category: 'success',
      content: `Frequency set to '${exportFrequency}'`,
    });
  }
};

export const toggleReadwiseEmailPreferences = async ({
  stateUpdateOptions,
  emailPreferenceKey,
  prettyNameForToast,
}: {
  stateUpdateOptions: StateUpdateOptionsWithoutEventName;
  emailPreferenceKey: keyof Profile['email_preferences'];
  prettyNameForToast: string;
}) => {
  const newValue = !globalState.getState().client.profile?.email_preferences[emailPreferenceKey];

  await updateState(
    (state) => {
      if (!state.client.profile) {
        return;
      }
      state.client.profile.email_preferences[emailPreferenceKey] = newValue;
    },
    { ...stateUpdateOptions, eventName: 'email-subscription-toggled', isUndoable: false },
  );

  await requestWithAuth(
    `${getServerBaseUrl()}/reader/api/email_preferences_toggle/${emailPreferenceKey}/${newValue}`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
    },
  );

  createToast({
    content: `${prettyNameForToast} turned ${newValue ? 'off' : 'on'}`,
    category: 'success',
  });
};

export const toggleMobileLargeFeedView = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.largeFeedView = !state.client.largeFeedView;
    },
    { ...options, eventName: 'mobile-toggle-large-feed-view', isUndoable: false },
  );
};

export const toggleAskToPasteUrls = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.askToPasteUrls = !state.client.askToPasteUrls;
    },
    { userInteraction: 'unknown', eventName: 'mobile-toggle-ask-to-paste-url', isUndoable: false },
  );
};

export const toggleKeepAwakeWhileReading = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.keepAwakeWhileReading = !state.client.keepAwakeWhileReading;
    },
    { userInteraction: 'unknown', eventName: 'mobile-toggle-keep-awake' },
  );
};

export const toggleHapticFeedback = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.mobileHapticsEnabled = !state.client.mobileHapticsEnabled;
    },
    { userInteraction: 'unknown', eventName: 'mobile-toggle-haptics' },
  );
};

export const toggleOpenLinksInApp = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.openLinksInApp = !state.client.openLinksInApp;
    },
    { userInteraction: 'unknown', eventName: 'mobile-toggle-open-links-in-app' },
  );
};

export const updateHeadphoneGesture = async ({
  name,
  action,
}: {
  name: 'nextTrack' | 'previousTrack';
  action: HeadphoneAction;
}): Promise<void> => {
  await updateState(
    (state) => {
      state.client.headphoneGestures[name] = action;
    },
    { userInteraction: 'unknown', eventName: 'mobile-headphone-gesture-updated' },
  );
};

export const setTheme = async (
  displayTheme: DisplayTheme,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.theme = displayTheme;
    },
    { ...options, eventName: 'theme-updated', isUndoable: false },
  );
};

export const setHasBeenShownReview = async (
  val: boolean,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  await updateState(
    (state) => {
      state.persistent.hasBeenShownReview = val;
    },
    { ...options, eventName: 'shown-review-prompt-updated' },
  );
};

export const toggleDarkModeTheme = async (): Promise<void> => {
  await updateState(
    (state) => {
      state.client.theme =
        state.client.theme === DisplayTheme.Default ? DisplayTheme.Dark : DisplayTheme.Default;
    },
    { eventName: 'dark-mode-theme-updated', userInteraction: 'unknown', isUndoable: false },
  );
};

export const toggleHidePanelsOnEnteringReadingView = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  const newValue = !globalState.getState().client.hideLeftPanelOnEnteringReadingView;

  const updateResult = await updateState(
    (state) => {
      state.client.hideLeftPanelOnEnteringReadingView = newValue;
      state.client.hideRightPanelOnEnteringReadingView = newValue;
    },
    { ...options, isUndoable: false, eventName: 'reading-view-panels-toggled-hidden-by-default' },
  );

  createToast({
    content: `Hide panels on entering reading view ${newValue ? 'on' : 'off'}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const toggleHideRightPanelOnEnteringReadingView = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  const newValue = !globalState.getState().client.hideRightPanelOnEnteringReadingView;

  const updateResult = await updateState(
    (state) => {
      state.client.hideRightPanelOnEnteringReadingView = newValue;
      state.client.hideLeftPanelOnEnteringReadingView = false;
    },
    { ...options, isUndoable: false, eventName: 'reading-view-right-panel-toggled-hidden-by-default' },
  );

  createToast({
    content: `Hide right panel on entering reading view ${newValue ? 'on' : 'off'}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const toggleHideLeftPanelOnEnteringReadingView = async (
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  const newValue = !globalState.getState().client.hideLeftPanelOnEnteringReadingView;

  const updateResult = await updateState(
    (state) => {
      state.client.hideLeftPanelOnEnteringReadingView = newValue;
      state.client.hideRightPanelOnEnteringReadingView = false;
    },
    { ...options, isUndoable: false, eventName: 'reading-view-left-panel-toggled-hidden-by-default' },
  );

  createToast({
    content: `Hide left panel on entering reading view ${newValue ? 'on' : 'off'}`,
    category: 'success',
    undoableUserEventId: (updateResult.userEvent as UserEvent).id,
  });
};

export const setProfile = (profile: Profile, options: StateUpdateOptionsWithoutEventName): void => {
  updateState(
    (state) => {
      if (isEqual(state.client.profile, profile)) {
        throw new CancelStateUpdate();
      }
      state.client.profile = profile;
    },
    {
      ...options,
      eventName: 'profile-updated',
    },
  );
};

export const updateSwipeAction = async (
  docList: SwipeDocList,
  swipeType: string,
  swipeAction: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  updateState(
    (state) => {
      if (state.client.swipeConfig?.[docList]?.[swipeType]) {
        state.client.swipeConfig[docList][swipeType] = swipeAction;
      }
    },
    {
      ...options,
      eventName: 'swipe-action-updated',
      shouldCreateUserEvent: false, // We don't care when this happens
      isUndoable: false,
    },
  );
};

export const setMobileLastAnnotationSubpanelOpened = (
  mobileLastAnnotationSubpanelOpened: MobileDocumentSubpanel,
) => {
  updateState(
    (state) => {
      state.client.mobileLastAnnotationSubpanelOpened = mobileLastAnnotationSubpanelOpened;
    },
    {
      eventName: 'set-mobile-last-annotation-subpanel-opened',
      shouldCreateUserEvent: false,
      userInteraction: 'tap',
      isUndoable: false,
    },
  );
};

export const setMobileSafeAreaInsets = async (safeAreaInsets: ClientState['mobileSafeAreaInsets']) => {
  const currentState = globalState.getState();
  if (isEqual(currentState.client.mobileSafeAreaInsets, safeAreaInsets)) {
    return;
  }
  return updateState(
    (state) => {
      state.client.mobileSafeAreaInsets = safeAreaInsets;
    },
    {
      eventName: 'set-mobile-safe-area-insets-updated',
      shouldCreateUserEvent: false,
      userInteraction: null,
      isUndoable: false,
    },
  );
};

export const toggleMobileDeveloperSetting = async (
  setting: keyof ClientState['mobileDeveloperSettings'],
  options: StateUpdateOptionsWithoutEventName,
) => {
  const newValue = !globalState.getState().client.mobileDeveloperSettings[setting];

  return updateState(
    (state) => {
      state.client.mobileDeveloperSettings[setting] = newValue;
    },
    { ...options, eventName: 'developer-options-toggled', isUndoable: false },
  );
};

export const setIsLibraryCollapsed = async (
  isLibraryCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isLibraryCollapsed = isLibraryCollapsed;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'is-library-collapsed-changed',
    },
  );
};

export const setIsTagsCollapsed = async (
  isTagsCollapsed: boolean,
  options?: Omit<Parameters<typeof updateState>[1], 'eventName'>,
): Promise<void> => {
  await updateState(
    (state) => {
      state.client.navigationSidebar.isTagsCollapsed = isTagsCollapsed;
    },
    {
      ...(options ?? {}),
      userInteraction: options?.userInteraction ?? 'click',
      isUndoable: false,
      eventName: 'is-tags-collapsed-changed',
    },
  );
};

export const setActiveSchemaMigration = async (value: boolean) => {
  await updateState(
    (state) => {
      state.activeSchemaMigration = value;
    },
    { eventName: 'active-schema-migration-updated', userInteraction: null },
  );
};
