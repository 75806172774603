import TauriSQL from '@tauri-apps/plugin-sql';

// eslint-disable-next-line restrict-imports/restrict-imports
import { Cache as LegacyCache } from '../../../background/cache.platform';
import { RxDBInstance } from '../../../types/database';
import { schema } from '../defineSchema';
import getDatabaseName from '../getDatabaseName';
import { migrationStartedKey, stateSyncingUpdatesKey, StorageVersion } from './constants';

export async function detectDatabaseStorageVersion(dbName: string, collectionToTest: string) {
  const collectionSchema = schema[collectionToTest].schema;
  try {
    const path = `sqlite:${dbName}.sqlite`;
    const db = await TauriSQL.load(path);

    const query = `select data from '_rxdb_internal-${collectionSchema.version}' where id = "storage-token|storageToken";`;
    const result: never[] = await db.select(query, []);
    await db.close(path);

    // empty result indicates we don't have a database yet and do not need to upgrade
    if (!result?.length) {
      return StorageVersion.Rxdb15;
    }

    const row = result[0] as { data: string };
    const rowData = JSON.parse(row.data);
    const rxdbDbVersionString = rowData.data.rxdbVersion;
    if (!rxdbDbVersionString) {
      return StorageVersion.Rxdb14;
    }

    const majorVersion = Number(rxdbDbVersionString.split('.')[0]);
    switch (majorVersion) {
      case 15:
        return StorageVersion.Rxdb15;
      default:
        throw new Error('unknown database version');
    }
  } catch (error) {
    /*
     * Almost always this is due to a non-existing db, we then just return the
     * latest version.
     */
    return StorageVersion.Rxdb15;
  }
}

export async function startStorageMigration() {
  const databaseName = getDatabaseName();

  const data = await readDataFromStores(databaseName, ['state_syncing_updates']);

  const stateSyncingUpdatesData = data.state_syncing_updates;
  await LegacyCache.setItem(stateSyncingUpdatesKey, JSON.stringify(stateSyncingUpdatesData));
  await LegacyCache.setItem(migrationStartedKey, 1);

  await removeAllDatabases();
}

export async function finishStorageMigration(db: RxDBInstance) {
  const migrationData = await LegacyCache.getItems([stateSyncingUpdatesKey, migrationStartedKey]);

  if (!migrationData[migrationStartedKey]) {
    return;
  }

  const stateSyncingUpdatesData = JSON.parse(migrationData[stateSyncingUpdatesKey]) || [];
  await db.collections.state_syncing_updates.bulkInsert(stateSyncingUpdatesData);

  await LegacyCache.removeItems([stateSyncingUpdatesKey, migrationStartedKey]);
}

export async function readDataFromStores(dbName: string, collections: string[]) {
  const allData: {
    [key in (typeof collections)[number]]?: unknown;
  } = {};

  const path = `sqlite:${dbName}.sqlite`;
  const db = await TauriSQL.load(path);
  for (const collection of collections) {
    const collectionSchema = schema[collection].schema;
    const query = `select data from '${collection}-${collectionSchema.version}';`;
    const result: never[] = await db.select(query, []);
    allData[collection] = result.map((record) => record);
  }
  await db.close(path);

  return allData;
}

export async function removeAllDatabases() {
  const databaseName = getDatabaseName();
  await deleteSQLiteDB(databaseName);
}

async function deleteSQLiteDB(dbName: string) {
  const path = `sqlite:${dbName}.sqlite`;
  const db = await TauriSQL.load(path);

  const query = `select name from 'sqlite_master' where type='table';`;
  const tableNames: string[] = await db.select(query, []);

  for (const row of tableNames) {
    // @ts-expect-error we know for sure that `name` is a column in the row object
    const query = `drop table if exists "${row.name}"`;
    await db.execute(query, []);
  }
  await db.close(path);
}
